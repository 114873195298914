import React from 'react';

import SummaryAccount from '../../components/SummaryAccount';

import './styles.css';

export default class ActiveAccount extends React.Component {
    render() {
      return (
        <>
          <SummaryAccount /> 
        </>
      );
    }
}  