import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './styles.css';

export default class Footer extends React.Component {
    render() {
      return (
        <footer>

        </footer>
      );
    }
}  
