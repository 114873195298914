import React from 'react';

import ContractSuccess from '../../components/ContractSuccess';

import './styles.css';

export default class ContratoSuccess extends React.Component {
    render() {
      return (
        <>
          <ContractSuccess /> 
        </>
      );
    }
}  