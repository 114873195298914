import React from 'react';

import Summary from '../../components/Summary';

import './styles.css';

export default class Contratacao extends React.Component {
    render() {
      return (
        <>
          <Summary /> 
        </>
      );
    }
}  