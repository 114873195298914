import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Filters from './pages/Filters';
import Contratacao from './pages/Contratacao';
import Contract from './pages/Contract';
import ContractWaiting from './pages/ContractWaiting';
import ContratoSuccess from './pages/ContractSuccess';
import ActiveAccount from './pages/ActiveAccount';
import KeyAccount from './pages/KeyAccount';
import SummaryKeyAccount from './pages/SummaryKeyAccount';


const Routes = () => (
    <BrowserRouter basename="/">
        <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/filters/:min/:max" component={Filters} />
            <Route exact path="/contratacao" component={Contratacao} />
            <Route exact path="/contrato" component={Contract} />
            <Route exact path="/contrato-aguardando" component={ContractWaiting} />
            <Route exact path="/contrato-sucesso" component={ContratoSuccess} />
            <Route exact path="/ativar-conta" component={ActiveAccount} />
            <Route exact path="/key-account" component={KeyAccount} />
            <Route exact path="/resumo-key-account" component={SummaryKeyAccount} />
        </Switch>
        <Footer />
    </BrowserRouter>
    
)

export default Routes;