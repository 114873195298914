import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class ContractSuccess extends React.Component {
  state = {
    name: '',
    email: '',
    phone: ''
  }

  componentDidMount(){
    this.getInfos();
  }

  getInfos = () => {
    let infos = window.getURLparams();

    this.setState({
      name: infos.form.keyAccount.name,
      email: infos.form.keyAccount.email,
      phone: infos.form.keyAccount.phone
    });
  }

    render() {
      return (
        <>
        <div className="container">
          <div className="contract contractSuccess">
            <Row>
              <Col sm={12}>
                <h3 className="title">TERMO DE USO DO MAILING I'MAX</h3>
                <h5 className="title">Assinatura digital finalizada com sucesso e o seu plano está ativo</h5>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
              <Form>
                  <Form.Row>
                    <Col sm={12}>
                      <span>E-mail com instruções iniciais de Onboarding foi enviado para o (a) Key Account da conta:</span>
                      <Form.Group>
                        <Form.Control type="text" value={this.state.name} readOnly/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control type="text" value={this.state.email} readOnly />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control type="text" value={this.state.phone} readOnly />
                      </Form.Group>
                      <small>Peça para o (a) Key Account verificar também sua caixa de SPAM.</small>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
        <div className="boxGray textCenter boxDoubts">
          <div className="container">
            <Row>
              <Col sm={12}>
                <h5 className="title">Mais informações e dúvidas sobre as instruções iniciais de Onboarding da sua conta?</h5>
                <p><b>ENTRE EM CONTATO COM JÚLIA GARCIA</b></p>
                <p><b>julia.garcia@i-maxpr.com | +55 11 95836-3764</b></p>
              </Col>
            </Row>
          </div>
        </div>
        </>
      );
    }
}  