import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class ConfigTechnical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      showModalEmail: false
    }
  }
  render() {
    const { showOverlay, showModalEmail } = this.state
    return (
      <>   
        <div className="container">
          <Row>
            <Col sm={12}>
              <h4 className="title">CONFIGURAÇÃO SPF</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxTechnical">
                      <p className="title">Status: NÃO REALIZADA</p>
                      <hr />
                    </div>
                    <div className="boxTechnical">
                      <p className="title">Nosso sistema identificou que a configuração SPF do seu domínio está como: </p>
                      <p className="answer">v=spf1 include:mg</p>
                      <hr />
                    </div>
                    <div className="boxTechnical">
                      <p className="title">O SPF é localizado na zona de DNS do provedor de hospedagem do domínio. Copie o texto abaixo e substitua o código no seu Painel Administrativo: </p>
                      <p className="answerGray">v=spf1 include:mg</p>
                      <button>Copiar código</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h4 className="title">CONFIGURAÇÃO DKIM</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxTechnical">
                      <p className="title">Status: NÃO REALIZADA</p>
                      <hr />
                    </div>
                    <div className="boxTechnical">
                      <p className="title">O DKIM do seu domínio é fixo. Para adicionar o I'Max, acesse o Painel Administrativo e insira uma nova entrada utilizando as configurações abaixo: </p>
                      <ul>
                        <li><p className="answer">TIPO: TXT</p></li>
                        <li><p className="answer">NOME: new0._domainkey</p></li>
                        <li><p className="answerGray">VALOR: v=DKIM1; k=rsa; p=MIG</p></li>
                      </ul>
                      <button>Copiar código</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <h4 className="title">REGISTRO CNAME</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxTechnical">
                      <p className="title">Status: NÃO REALIZADO</p>
                      <hr />
                    </div>
                    <div className="boxTechnical">
                      <p className="title">Acesse o Painel Administrativo do seu domínio e crie uma nova entrada CNAME para o I'Max com as informações abaixo: </p>
                      <ul>
                        <li><p className="answer">TIPO: CNAME</p></li>
                        <li><p className="answer">HOST: img</p></li>
                        <li><p className="answerGray">DADO: img.i-mpr.com</p></li>
                      </ul>
                      <button>Copiar dado</button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="textCenter">
                <h4>Quer compartilhar as configurações com uma pessoa da sua área técnica?</h4>
                <button onClick={() => this.setState({ showModalEmail: !showModalEmail,  showOverlay: !showOverlay })}>Enviar e-mail</button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxTechnical textCenter">
                      <p className="title" style={{marginTop: 23}}>PRECISA DE AJUDA TÉCNICA DO I'MAX PARA REALIZAR AS CONFIGURAÇÕEOS SPF E DKIM E O REGISTRO CNAME?</p>
                      <p id="contactTechical">Entre em contato com Júlia Garcia: julia.garcia@i-maxpr.com | +55 11 95836-3764</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      {showOverlay && (
        <div id="overlay"></div>
      )}
      { showModalEmail && (
        <>
          <div className="modal text-center modalGreen">
            <button className="closeModal" onClick={() => this.setState({ showModalEmail: !showModalEmail,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Digite o e-mail para compartilhar as configurações SPF e DKIM e registro CNAME.</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form style={{marginTop: 25}}>
                    <Form.Group>
                      <Form.Control type="email" placeholder="Digite o e-mail da sua área técnica" />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <button>Enviar e-mail</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      </>
    );
  }
}  