import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';
import { FaAngleDown } from 'react-icons/fa';

import './styles.scss';

export default class ContractKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDetails: false
    }
  }

  
  
  render() {
    const { showMoreDetails } = this.state
    return (
      <>
      <div className="container">
          <Row>
            <Col sm={12}>
              <div className="boxResultAccount" id="boxResultKeyAccount">
                <Row>
                  <Col sm={8}>
                    <p><b>CONTRATO Nº 129083297012093712097321093</b></p>
                    <p>Valor total: R$ 00.000,00</p>
                    <p>Termina em 10/05/2021</p>
                    <p>Status: ATIVO</p>
                  </Col>
                  <Col sm={4}>
                    <button>Baixar contrato em PDF</button>
                  </Col>
                </Row>
                <hr />
                <a className="seeMoreDetails" href="#" onClick={() => this.setState({ showMoreDetails: !showMoreDetails })}>Ver mais detalhes <FaAngleDown className={showMoreDetails ? 'icUp' : 'icDown'} /></a>
                {showMoreDetails &&
                  <div className="boxMoreDetails" id="boxMoreDetailsContract">
                    <Row>
                      <Col sm={6}>
                        <div className="boxQuestions boxResultContract">
                          <p className="title">Mailing de Imprensa Brasil</p>
                          <p className="answer">Parcial com 1 região do País</p>
                          <p className="answerGreenLight"> Região Centro-Oeste</p>
                          <hr />
                          <p className="title">Públicos adicionais</p>
                          <p className="answer">Mailing Governo e Terceiro Setor: <span>SEM ACESSO</span></p>
                          <p className="answer">Mailing de Influenciadores: <span>SEM ACESSO</span></p>
                          <p className="answer">Mailing de Imprensa LATAM: <span>3 PAÍSES</span></p>
                          <p className="answerGreenLight"> Argentina / Chile / México</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="boxQuestions boxResultContract">
                          <p className="title">Tempo de uso</p>
                          <p className="answer">6 meses</p>
                          <p className="answerGreenLight"> Vigência de 10/05/2021 à 10/11/2021</p>
                          <hr />
                          <p className="title">Quantidade de usuário(s)</p>
                          <p className="answer">5 usuário(s) com acesso à plataforma</p>
                          <hr />
                          <p className="title">Quantidade de disparos de e-mails</p>
                          <p className="answer">15 mil disparos/mês</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              </div>
            </Col>
          </Row>
      </div>
      </>
    );
  }
}  