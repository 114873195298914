import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import './styles/normalize.css';

import './styles/_colors.scss';

import Routes from './routes';

const App = () => (
  <Routes />
);

export default App;
