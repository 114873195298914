import React from 'react';
import { Col, Row, Form, Table, InputGroup, FormControl } from 'react-bootstrap';

import { GoSearch } from 'react-icons/go';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      showModalReset: false,
      showModalDrop: false,
      showModalNewUser: false
    }
  }
 
  render() {
    const { showOverlay, showModalReset, showModalDrop, showModalNewUser } = this.state
    return (
      <>
        <div className="container">
          <h4 className="title">DETALHAMENTO DE USUÁRIOS</h4>
          <div className="boxResultAccount" id="boxUsers">
            <Row>
              <Col sm={6}>
                <div className="boxQuestions">
                  <p className="title">Plano(s) contratado(s) com:</p>
                  <p className="answer">200 usuário(s) com acesso à plataforma</p>
                  <p className="answerGreenLight"> usuário(s) cadastrado(s) no momento</p>
                </div>
              </Col>
              <Col sm={3}>
                <button onClick={() => this.setState({ showModalNewUser: !showModalNewUser,  showOverlay: !showOverlay })}>Cadastrar novo usuário</button>
              </Col>
              <Col sm={3}>
                <Form>
                  <Form.Group>                    
                    <Form.Control type="email" placeholder="Buscar um usuário" />
                    <GoSearch />                    
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={12}>
              <div className="boxTable" id="tableUsers">
                <div className="boxShowUsers">
                  <span><i>Exibindo até 25 usuários por página</i></span>
                  <hr />
                </div>
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>NOME</th>
                      <th style={{width: 300}}>E-MAIL</th>
                      <th>CADASTRO</th>
                      <th>ÚLTIMO ACESSO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>João do Exemplo com Sobrenome</td>
                      <td>joao.sobrenome@nomeempresa.com.br</td>
                      <td>10/05/2021</td>
                      <td>10/05/2021</td>
                      <td><button onClick={() => this.setState({ showModalReset: !showModalReset,  showOverlay: !showOverlay })}>Resetar acesso</button></td>
                      <td><button onClick={() => this.setState({ showModalDrop: !showModalDrop,  showOverlay: !showOverlay })}>Excluir usuário</button></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div id="alertUsers">
                <p><b>O que é RESETAR ACESSO? </b>
                  Como o acesso de cada usuário é restrito a apenas um dispositivo, resetar o acesso é retornar às configurações iniciais de um usuário para que ele possa usar a plataforma I'Max por outro dispositivo.
                  É possível resetar o acesso de um usuário apenas 1 vez e, para resetá-lo novamente, será necessário entrar em contato com o Suporte I'Max.
                </p>
                <p><b>Posso excluir qualquer usuário? </b>
                  Sim, mas um usuário excluído só pode ser cadastrado novamente após 30 dias.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      {showOverlay && (
        <div id="overlay"></div>
      )}
      { showModalReset && (
        <>
          <div className="modal text-center modalGreen">
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Quer resetar o acesso deste usuário?</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form>
                    <Form.Group>
                      <Form.Control type="email" value="joao.sobrenome@nomeempresa.com.br" readOnly />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span>Como o acesso de cada usuário é restrito a apenas um dispositivo, este usuário poderá acessar a plataforma do I'Max utilizando outro dispositivo após o processo de resetar o acesso. 
                    É possível resetar o acesso de um usuário apenas 1 vez e, para resetá-lo novamente, será necessário entrar em contato com o Suporte I'Max.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <button className="btnWhite" onClick={() => this.setState({ showModalReset: !showModalReset,  showOverlay: !showOverlay })}>Não, obrigado</button>
                </Col>
                <Col sm={6}>
                  <button>Resetar acesso</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      { showModalDrop && (
        <>
          <div className="modal text-center modalGreen">
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Tem certeza que deseja excluir este usuário?</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form>
                    <Form.Group>
                      <Form.Control type="email" value="joao.sobrenome@nomeempresa.com.br" readOnly />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span>O usuário só poderá ser cadastrado novamente<br /> nesta conta após 30 dias.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <button className="btnWhite" onClick={() => this.setState({ showModalDrop: !showModalDrop,  showOverlay: !showOverlay })}>Não, obrigado</button>
                </Col>
                <Col sm={6}>
                  <button>Excluir usuário</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      { showModalNewUser && (
        <>
          <div className="modal text-center modalGreen">
            <button className="closeModal" onClick={() => this.setState({ showModalNewUser: !showModalNewUser,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Cadastrar novo usuário</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form id="newUser">
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Digite o nome do novo usuário" />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <InputGroup>
                      <FormControl id="inlineFormInputGroupUsername" placeholder="Nome antes da @ no e-mail do novo usuário" />
                      <InputGroup.Prepend>
                        <InputGroup.Text>@nomeempresa.com.br</InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span style={{marginTop: 15}}>Após o cadastro, o novo usuário já pode acessar a plataforma usando o e-mail cadastrado acima para fazer o login ou utilizar o link que será enviado no seu<br /> e-mail.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <button>Criar usuário</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      </>
    );
  }
}  