import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SummaryKey from '../SummaryKey';
import ConfigFinancial from '../ConfigFinancial';
import ConfigTechnical from '../ConfigTechnical';
import Users from '../Users';

import './styles.scss';

export default class PanelKeyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSummary: true,
      showFinancial: false,
      showUsers: false,
      showTechnical: false
    }
  }

  next = () => {
    window.location.href=`/key-account`;
  }
  
  render() {
    const { showSummary, showFinancial, showUsers, showTechnical } = this.state
    return (
      <>   
      <div className="container">
        <div className="summaryKey">
          <Row>
            <Col sm={12}>
              <h3 className="title">PAINEL ADMINISTRATIVO I'MAX</h3>
              <h5 className="title">Área exclusiva para o Key Account</h5>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <button className="btnsKey" onClick={() => this.next()}>Acessar menu principal</button>
            </Col>
            <Col sm>
              <button className={`btnsKey${showSummary ? ' active' : ''}`} onClick={() => this.setState({ showSummary: true, showFinancial: false, showUsers: false, showTechnical: false })}>Resumo da conta</button>
            </Col>
            <Col sm>
              <button className={`btnsKey${showFinancial ? ' active' : ''}`} onClick={() => this.setState({ showSummary: false, showFinancial: true, showUsers: false, showTechnical: false })}>Configurações financeiras</button>
            </Col>
            <Col sm>
              <button className={`btnsKey${showUsers ? ' active' : ''}`} onClick={() => this.setState({ showSummary: false, showFinancial: false, showUsers: true, showTechnical: false })}>Usuários cadastrados</button>
            </Col>
            <Col sm>
              <button className={`btnsKey${showTechnical ? ' active' : ''}`} onClick={() => this.setState({ showSummary: false, showFinancial: false, showUsers: false, showTechnical: true })}>Configurações técnicas</button>
            </Col>
          </Row>
        </div>
      </div>
      {showSummary &&
        <div className="boxGray boxGraySummaryAccount">
          <SummaryKey />
        </div>
      }
      {showFinancial && 
        <div className="boxGray boxGraySummaryAccount">
          <ConfigFinancial />
        </div>        
      }
      {showUsers && 
        <div className="boxGray boxGraySummaryAccount">
          <Users />
        </div>        
      }
      {showTechnical && 
        <div className="boxGray boxGraySummaryAccount">
          <ConfigTechnical />
        </div>        
      }
      </>
    );
  }
}  