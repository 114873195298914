import React from 'react';
import { Col, Row, Form, Table } from 'react-bootstrap';

import './styles.scss';

export default class ConfigFinancial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  
  
  render() {
    return (
      <>
        <div className="container">
          <h4 className="title">RESPONSÁVEIS FINANCEIROS</h4>
          <div className="boxResultAccount">
            <Row>
              <Col sm={12}>
                <div className="boxConfiguration">
                  <p>Clique na caixa para alterar ou adicionar o(s) e-mail(s) que receberão os dados de pagamento, notas fiscais e outras informações financeiras do(s) contratos(s):</p>
                  <Form>
                    <Form.Group>
                      <Form.Row>
                        <Col sm={10}>
                          <Form.Control type="email" value="nome1@empresa.com.br, nome2@empresa.com.br, nome3@empresa.com.br, nome4@empresa.com.br, nome5@empresa.com.br" />
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </Form>
                  <span><i>Adicione quantos e-mails quiser, mas os separe por vírgula e espaço.</i></span>
                  <button className="btnCenter">Salvar responsáveis financeiros</button>
                </div>
              </Col>
            </Row>
          </div>
          <h4 className="title">LISTA DE FATURAS</h4>
          <div className="boxResultAccount" id="resultList">
            <Row>
              <Col sm={12}>
                <p className="answerGreenLight">X boleto(s) em aberto referente ao(s) contrato(s) ativo(s).</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={12}>
              <div className="boxTable">
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>CONTRATO</th>
                      <th>PARCELA</th>
                      <th>VALOR</th>
                      <th>DATA FATURA</th>
                      <th>VENCIMENTO</th>
                      <th>SITUAÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td width={100}>01 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/05/2021</td>
                      <td>20/05/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td>02 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/06/2021</td>
                      <td>20/06/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td>03 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/07/2021</td>
                      <td>20/07/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td>04 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/08/2021</td>
                      <td>20/08/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td>05 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/09/2021</td>
                      <td>20/09/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                    <tr>
                      <td>000000000000000131298447</td>
                      <td>06 DE 06</td>
                      <td>R$ 0.000,00</td>
                      <td>10/10/2021</td>
                      <td>20/10/2021</td>
                      <td>ATRASADO</td>
                      <td><button>Baixar boleto</button></td>
                      <td><button>Nota fiscal</button></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}  