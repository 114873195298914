import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './styles.css';

export default class KeyAccount extends React.Component {
  next = () => {
    window.location.href=`/resumo-key-account`;
  }
    render() {
      return (
        <>
          <div className="container">
            <div className="summaryKey">
              <Row>
                <Col sm={12}>
                  <h3 className="title">PLATAFORMA I'MAX</h3>
                  <h5 className="title">Acesso exclusivo ao Key Account. <b>O que você precisa?</b></h5>
                </Col>
              </Row>
              <div className="boxBtnsKey">
                <Row>
                  <Col sm={4}>
                    <button>Usar a plataforma</button>
                    <p>Utilize seu mailing, envie releases e veja os resultados dos seus disparos. Antes de usar a plataforma, o Key Account precisa incluir seu e-mail como usuário no painel administrativo.</p>
                  </Col>
                  <Col sm={4}>
                    <button onClick={() => this.next()}>Ver painel administrativo</button>
                    <p>Veja o resumo do contrato, seus dados financeiros, adicione ou retire usuários da conta e acesse as configurações técnicas de SPF e DKIM.</p>
                  </Col>
                  <Col sm={4}>
                    <button>Acessar loja online</button>
                    <p>Compre disparos de e-mails excedentes ao seu contrato ativo, adicione domínios, inclua mais públicos ao seu mailing ou mude de plano.</p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      );
    }
}  