export const toFloat = value => {
    let v = value;
    v = v.replace('R$ ', '');
    v = v.replace('.', '');
    v = v.replace(',', '.');
    v = parseFloat(v);
    return v;
}

export const editorialsToString = (value, geographicMailing) => {
    if(geographicMailing === 'unlimited') return 'Ilimitado';
    if(value !== 0) return value;
    
    return 'Não incluso';
}

export const regionToString = value => {
    if(value === 0) return 'Não incluso';
    
    let v = value;
    switch (v) {
        case "unlimited":
            return "Nacional";
            break;
        
        case 2:
            return "2 regiões";
            break;

        case 1:
            return "1 região";
            break;
    
        default:
            return "0";
            break;
    }
}

export const contractToString = value => {
    let string = value === 1 ? 'mês' : 'meses';
    return `${value} ${string}`;
}

export const toStringPrice = value => {
    let v = value.toLocaleString('pt-br', {minimumFractionDigits: 2});
    return `R$ ${v}`;
}

export const usersToString = value => {
    let string = value === 1 ? 'usuário' : 'usuários';
    return `Acesso a ${value} ${string}`;
}

export const toStringQty = value => {
    let v = value.toLocaleString('pt-br', {minimumFractionDigits: 0});
    return v;
}

export const journalistsToString = (value, geographicMailing) => {
    if(geographicMailing === 'unlimited') return 'Ilimitado';
    let string = value === 1 ? 'jornalista' : 'jornalistas';
    let v = toStringQty(value);
    return `${v} ${string}/mês`;
}

export const governmentToString = value => {
    return value === 'unlimited' ? 'Ilimitado' : 'Não incluso';
}

export const influencerToString = value => {
    if(value === 0) return 'Não incluso';

    if(value === 'unlimited'){
        return 'Ilimitado';
    } else {
        let string = value === 1 ? 'influenciador' : 'influenciadores';
        let v = toStringQty(value);
        return `${v} ${string}/mês`;
    }
}

export const latamToString = value => {
    if(value === 0) return 'Não incluso';
    
    if(value === 'unlimited'){
        return 'Ilimitado';
    } else {
        let string = value === 1 ? 'país' : 'países';
        let v = toStringQty(value);
        return `${v} ${string}`;
    }
}

export const priceMask = value => {
    var v = value.replace(/\D/g,'');
	v = (v/100).toFixed(2) + '';
	v = v.replace(".", ",");
	v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
	v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
	value = v;
    return `R$ ${value}`;
}