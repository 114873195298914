import React from 'react';
import InputRange from 'react-input-range';

import { Col, Row, Form, Button, InputGroup } from 'react-bootstrap';

import { priceMask } from '../../useful/conversions';

import './styles.scss';

import 'react-input-range/lib/css/index.css';
 
class Range extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      inputs: { min: 'R$ 500,00', max: 'R$ 2.000,00' },
      value: { min: 500, max: 2000 },
      limits: { min: 299, max: 7880}
    };
  }

  next = () => {
    window.location.href=`/filters/${this.state.value.min}/${this.state.value.max}`;
  }

  updateValue = (value, fromInput = false) => {
    let interval = 1500;
    let { min, max} = this.state.value;
    
    if(value.min === min && (value.max - value.min > interval)){
      value.min = value.max - interval;
    }

    if(value.max === max && (value.max - value.min > interval)){
      value.max = value.min + interval;
    }

    if(value.max > this.state.limits.max){
      value.max = this.state.limits.max;
      value.min = this.state.limits.max - interval;
    }
    
    if(value.min < this.state.limits.min){
      value.min = this.state.limits.min;
      value.max = this.state.limits.min + interval;
    }

    let inputs = {};
    if(!fromInput){
      inputs.min = priceMask(value.min.toString() + '00');
      inputs.max = priceMask(value.max.toString() + '00');
    }

    this.setState({ value, inputs });
  }

  handleInputChange = (e) => {
    // let name = e.target.name;
    // let v = e.target.value;
    // v = priceMask(v);

    // this.setState({ inputs: { ...this.state.inputs, [name]: v}});
  }

  handleInputOut = () => {
    // let min = this.state.inputs.min;
    // min = min.replace('R$ ', '');
    // min = min.replace('.', '');
    // min = min.replace(',', '.');
    // min = parseFloat(min);

    // let max = this.state.inputs.max;
    // max = max.replace('R$ ', '');
    // max = max.replace('.', '');
    // max = max.replace(',', '.');
    // max = parseFloat(max);

    // let value = {};
    // value.min = min;
    // value.max = max;

    // this.updateValue( value, true );
  }
 
  render() {
    return (
      <>
        <div className="priceRange">
          <span>Mínimo: R$299,00</span>
          <span>Máximo: R$7.880,00</span>
        </div>
        <Form>
          <Form.Row>
            <Col>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>DE</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="min" type="text" placeholder="" value={this.state.inputs.min} onBlur={() => this.handleInputOut()} onChange={(e) => this.handleInputChange(e)} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>ATÉ</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control name="max" type="text" placeholder="" value={this.state.inputs.max} onBlur={() => this.handleInputOut()} onChange={(e) => this.handleInputChange(e)} />
              </InputGroup>
            </Col>
          </Form.Row>
        </Form>
        <div className="boxRange">
          <InputRange
            formatLabel={value => `R$ ${value}`}
            maxValue={this.state.limits.max}
            minValue={this.state.limits.min}
            value={this.state.value}
            onChange={(value) => this.updateValue(value)} />
        </div>
        <button onClick={() => this.next()}>Ver planos</button>
      </>
    );
  }
}

export default Range;