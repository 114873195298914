import React from 'react';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import { withRouter } from "react-router-dom";

import animateScrollTo from 'animated-scroll-to';

import { RiCloseCircleLine } from 'react-icons/ri';

import axios from 'axios';

import ReactTooltip from 'react-tooltip';

import ResultFilter from '../ResultFilter';

import skus from '../../skus';

import { priceMask, toFloat } from '../../useful/conversions';

import { FaAngleDown, FaQuestionCircle } from 'react-icons/fa';

import { phoneMask } from '../../useful/masks';

import './styles.scss';

export class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDiv: true,
      showOverlay: false,
      showPrice: false,
      showTime: false,
      showUsers: false,
      showRegions: false,
      showAudience: false,
      showMailing: false,
      showEditorials: false,
      showMonth: false,
      showModalPrice: false,
      showPriceChange: false,
      skus: [],
      filteredSkus: [],
      initPrice: priceMask((this.props.match.params.min*100).toString()),
      finalPrice: priceMask((this.props.match.params.max*100).toString()),
      prevInitPrice: '',
      prevFinalPrice: '',
      installments: [],
      users: [],
      unlimited: [],
      government: [],
      influencer: [],
      latam: [],
      editorialMailing: [],
      geographicMailing: [],
      journalistMailing: [],
      ordernation: 'lowestPrice',
      phone: '',
    }

    this.resultRef = React.createRef();
  }

  componentDidMount(){

    this.scrollToMyRef();

    this.getPhone();

    let { min, max } = this.props.match.params;
    min = priceMask((min*100).toString());
    max = priceMask((max*100).toString());
    this.getSkus();
  }

  scrollToMyRef = () => {
    setTimeout(
      function() {
        animateScrollTo(this.resultRef.current.offsetTop - 150, {
          speed: 2500
        });
      }
      .bind(this),
      200
    );
  };

  getPhone = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ph = urlParams.get('ph');
    let phone = window.decodePhone(ph);
    console.log(phone);
    phone = phoneMask(phone.toString());
    this.setState({ phone });

  }

  checkHasInSkus = (param, value) => {
    let has = false;
    if(value === 'partial'){
      has = this.state.skus.find(s => s[param] !== 'unlimited');
    } else {
      has = this.state.skus.find(s => s[param] === value);
    }
    return !!has;
  }

  getSkus = async() => {
    this.setState({ showPriceChange: false });
    let pricestart = this.state.initPrice;
    pricestart = pricestart.replace('R$ ', '');
    pricestart = pricestart.replace('.', '');
    pricestart = pricestart.replace(',', '');

    let priceend = this.state.finalPrice;
    priceend = priceend.replace('R$ ', '');
    priceend = priceend.replace('.', '');
    priceend = priceend.replace(',', '');

    const result = await axios.get(`https://www.i-maxpr.com/beowulf/API/getPlans.php?key=666999&pricestart=${pricestart}&priceend=${priceend}`)
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log(err.response);
      });

    if(!result) return;

    let skusJSON = result.data.skus[0];
    let s = [];
    
    // Filtros
    let installments = [];
    let users = [];
    let unlimited = [];
    let government = [];
    let influencer = [];
    let latam = [];
    let editorialMailing = [];
    let geographicMailing = [];
    let journalistMailing = [];
    
    // Example
    // installments: [1,3,4,6,12],
    // users: [1,2,5,10,20,60,100],
    // unlimited: ["unlimited"],
    // government: ['unlimited'],
    // influencer: ['unlimited', 'partial'],
    // latam: ['unlimited', 'partial'],
    // editorialMailing: [],
    // geographicMailing: [],
    // journalistMailing: [],

    skusJSON.map(sku => {
      let n = {
        sku: sku.sku,
        government: sku.government === "Ilimitado" ? "unlimited" : 0,
        influencer: sku.influencer === "Ilimitado" ? "unlimited" : parseInt(sku.influencer),
        latam: sku.latam === "Ilimitado" ? "unlimited" : parseInt(sku.latam),
        users: parseInt(sku.users),
        submissions: parseInt(sku.submissions),
        geographicMailing: sku.geographicMailing === "Ilimitado" ? "unlimited" : parseInt(sku.geographicMailing),
        editorialMailing: sku.editorialMailing === "0" ? 0 : sku.editorialMailing,
        journalistMailing: parseInt(sku.journalistMailing),
        installments: sku.installments,
        price: sku.price / 100,
        valuePerMonth: sku.valuePerMonth / 100,
        paymentoptions: sku.paymentoptions,
        desc: sku.desc,
        caveat: sku.caveat,
        unlimited : sku.unlimited
      };

      if(!n.latam){
        n.latam = 0;
      }

      s.push(n);

      if(!installments.includes(n.installments)) installments.push(n.installments);
      if(!users.includes(n.users)) users.push(n.users);
      if(!government.includes(n.government)) government.push(n.government);

      if(n.influencer === 'unlimited' && !influencer.includes('unlimited')) influencer.push('unlimited');
      if(n.influencer !== 'unlimited' && !influencer.includes('partial')) influencer.push('partial');

      if(n.latam === 'unlimited' && !latam.includes('unlimited')) latam.push('unlimited');
      if(n.latam !== 'unlimited' && !latam.includes('partial')) latam.push('partial');

      if(!editorialMailing.includes(n.editorialMailing)) editorialMailing.push(n.editorialMailing);
      if(!geographicMailing.includes(n.geographicMailing)) geographicMailing.push(n.geographicMailing);
      if(!journalistMailing.includes(n.journalistMailing)) journalistMailing.push(n.journalistMailing);

      if(!unlimited.includes(n.unlimited)) unlimited.push(n.unlimited);

    });
  
    this.setState({
      skus: s, 
      unlimited, 
      installments, 
      users, 
      government, 
      influencer, 
      latam, 
      editorialMailing, 
      geographicMailing, 
      journalistMailing
    }, () => this.filterSkus());
  }

  handleInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    value = priceMask(value);

    this.setState({ [name]: value, showPriceChange: true });
  }

  filterSkus = () => {
    let filteredSkus = this.state.skus.filter(function(item) {
      if(item.sku === 720) return true;
      
      if(!this.state.installments.includes(item.installments)) return false;
      if(!this.state.users.includes(item.users)) return false;
      
      // Governo
      if(!this.state.government.includes('unlimited') && item.government === 'unlimited') return false;
  
      // Influencers
      if(!this.state.influencer.includes('partial') &&
        item.influencer !== 'unlimited' &&
        item.influencer !== 0
      ) return false;

      if(
        !this.state.influencer.includes('unlimited') &&
        item.influencer === 'unlimited'
      ) return false;

      // LATAM
      if(!this.state.latam.includes('partial') &&
        item.latam !== 'unlimited' &&
        item.latam !== 0
      ) return false;

      if(
        !this.state.latam.includes('unlimited') &&
        item.latam === 'unlimited'
      ) return false;

      

      // MAILING BRASIL
      if(
        !this.state.unlimited.includes(1) &&
        item.unlimited === 1
      ) return false;
      
      

      if(this.state.unlimited.includes(0)){
        // REGIÃO
        if(!this.state.geographicMailing.includes(1) && item.geographicMailing === 1 && item.geographicMailing !== 'unlimited') return false;
        if(!this.state.geographicMailing.includes(2) && item.geographicMailing === 2 && item.geographicMailing !== 'unlimited') return false;
        
        // EDITORIAS
        if(!this.state.editorialMailing.includes(item.editorialMailing) && item.editorialMailing !== 0) return false;
        
        // JORNALISTA / QUANTIDADS
        if(!this.state.journalistMailing.includes(item.journalistMailing) && item.journalistMailing !== 0) return false;
        
      } else {
        if(item.unlimited === 0) return false;
      }

      return true;
    }.bind(this));

    this.setState({ filteredSkus }, () => this.orderSkus());
  }
  
  orderSkus = () => {
    let filteredSkus = this.state.filteredSkus.sort( this.compare );
    let recomended = filteredSkus.find(o => o.sku === 720);
    let index = filteredSkus.indexOf(recomended);

    filteredSkus.splice(index, 1);
    filteredSkus.unshift(recomended);

    this.setState({ filteredSkus });
  }
  
  compare = ( a, b ) => {
    let param = 'valuePerMonth';
    let order = 'asc';

    switch (this.state.ordernation) {
      case 'lowestPrice':
        param = 'valuePerMonth';
        order = 'asc';
        break;

      case 'highestPrice':
        param = 'valuePerMonth';
        order = 'desc';
        break;

      case 'lowestSubmissions':
        param = 'submissions';
        order = 'asc';
        break;

      case 'highestSubmissions':
        param = 'submissions';
        order = 'desc';
        break;

      case 'longerInstallments':
        param = 'installments';
        order = 'asc';
        break;
    }

    if ( a[param] < b[param] ){
      return order === 'asc'  ? -1 : 1;
    }
    if ( a[param] > b[param] ){
      return order === 'asc'  ? 1 : -1;
    }

    return 0;
  }

  handleOrdernationChange = (e) => {
    let value = e.target.value;
    this.setState({ ordernation: value }, () => this.orderSkus());
  }

  handleFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let checked = e.target.checked;
    let param = name;

    if(name === 'installments') value = parseInt(value);
    if(name === 'users') value = parseInt(value);
    if(name === 'journalistMailing') value = parseInt(value);
    if(name === 'geographicMailing') value = parseInt(value);
    if(name === 'unlimited') value = parseInt(value);

    if(checked){
      let hasInSkus = this.checkHasInSkus(param, value);
      if(!hasInSkus){
        this.getValuesByParam(param, value);
        return;
      }
    }

    let items = this.state[name];

    if(items.includes(value) && checked) return;

    if(items.includes(value) && !checked) {
      let index = items.indexOf(value);
      items.splice(index, 1);
    };

    if(!items.includes(value) && checked) {
      items.push(value);
    };


    this.setState({ [name] : items }, () => this.filterSkus());
  }

  changePrices = () => {
    this.setState({ showModalPrice: false, showOverlay: false, initPrice: this.state.prevInitPrice, finalPrice: this.state.prevFinalPrice }, () => this.getSkus());
  }

  getValuesByParam = async (param, value) => {
    value = value === 'unlimited' ? 'Ilimitado' : value;

    console.log(param, value);

    axios.get(`https://www.i-maxpr.com/beowulf/API/getPlans.php?key=666999`)
      .then(res => {
        let min = toFloat(this.state.initPrice) * 100;
        let max = toFloat(this.state.finalPrice) * 100;

        let skus = res.data.skus[0];
        if(skus){
          skus.map(s => {
            let update = false;

            if(s[param] === value){
              update = true;
            } else if(value === 'partial'){
              if(s[param] !== 'Ilimitado') update = true;
            }

            if(update){
              if(s.valuePerMonth > max) max = s.valuePerMonth;
              if(s.valuePerMonth < min) min = s.valuePerMonth;
            }

          });
        }

        
        
        let prevInitPrice = priceMask((min).toString());
        let prevFinalPrice = priceMask((max).toString());

        if(prevFinalPrice !== this.state.finalPrice || prevInitPrice !== this.state.initPrice){
          this.setState({ prevInitPrice, prevFinalPrice, showModalPrice: true, showOverlay: true });
        } else {
          alert('Valor não encontrado na API');
        }
      })
      .catch(err => {
        console.log('catch', err.response);
      });
  }

  render() {

    const { showDiv, showOverlay, showPrice, showTime, showUsers, showRegions, showAudience, showMailing, showEditorials, showMonth, showModalPrice } = this.state
    
    return (
      <> 
      {showOverlay && (
        <div id="overlay"></div>
      )}
      {showModalPrice &&
        <>
          <div className="modal text-center" id="modalDownload">
            <button className="closeModal" onClick={() => this.setState({ showModalPrice: false,  showOverlay: false })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">ALTERAÇÃO DE FAIXA DE PREÇO</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ul>
                    <li>
                      <p>
                        Para visualizar planos com o filtro selecionado, sua faixa de preço será alterada para:
                      </p>
                    </li>
                    <li>
                      <p>
                        De <b>{this.state.prevInitPrice}</b> até <b>{this.state.prevFinalPrice}</b>
                      </p>
                    </li>
                  </ul>
                </Col>
              </Row>
              <button onClick={() =>  this.changePrices()}>Aplicar ajuste</button>
            </div>
          </div>
        </>
      }
      <div className="container">
        <div className="filterMobile">
          <div id="btnFilterMobile" className="btnHideDesk" onClick={() => this.setState({ showOverlay: !showOverlay })}>
              Filtrar <FaAngleDown />
          </div>
                        
              <div className={`menuFilter ${this.state.showOverlay ? `show` : `hide`}`}>
                <div className="filterDesktop">
                  <Row>
                    <Col sm={7}>
                      <div className="boxFilters">
                        <div className="boxTitle" onClick={() => this.setState({ showPrice: !showPrice })}>
                          <p className="title">FAIXA DE PREÇO / MÊS</p>
                          <FaAngleDown />
                        </div>
                        <div className={`boxBody ${this.state.showPrice ? `show` : `hide`}`}>
                          <Form className="formPrice">
                            <Form.Row>
                              <Col md>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>DE</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control name="initPrice" type="text" placeholder="R$ 0,00" value={this.state.initPrice} onChange={this.handleInputChange} />
                                </InputGroup>
                              </Col>
                              <Col md>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>ATÉ</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control name="finalPrice" type="text" placeholder="R$ 0,00" value={this.state.finalPrice} onChange={this.handleInputChange} />
                                </InputGroup>
                              </Col>
                              <Col md xs={12}>
                                <button type="button" onClick={() => this.getSkus()}>Alterar faixa de preço</button>
                              </Col>
                            </Form.Row>
                          </Form>
                          {this.state.showPriceChange &&
                            <p className="priceChangeAlert">Para aplicar a alteração, clique no botão "Alterar faixa de preço"</p>
                          }
                        </div>
                      </div>
                    </Col>
                    <Col sm={5}>
                      <div className="boxFilters">
                        <div className="boxTitle" onClick={() => this.setState({ showTime: !showTime })}>
                          <p className="title">TEMPO DE CONTRATO</p>
                          <FaAngleDown />
                        </div>
                        <div className={`boxBody ${this.state.showTime ? `show` : `hide`}`}>
                          <Form>
                            <Form.Row>
                              <Col sm={12} lg>
                                <Form.Check type="checkbox" label="Apenas 1 mês" name="installments" value="1" checked={this.state.installments.includes(1)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="2 meses" name="installments" value="2" checked={this.state.installments.includes(2)} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                              <Col lg>
                                <Form.Check type="checkbox" label="3 meses" name="installments" value="3"  checked={this.state.installments.includes(3)} onChange={(e) => this.handleFilterChange(e)}/>
                                <Form.Check type="checkbox" label="4 meses" name="installments" value="4" checked={this.state.installments.includes(4)} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                              <Col sm={12} lg>
                                <Form.Check type="checkbox" label="6 meses" name="installments" value="6" checked={this.state.installments.includes(6)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="12 meses" name="installments" value="12" checked={this.state.installments.includes(12)} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                            </Form.Row>
                          </Form>
                        </div>                
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5}>
                      <div className="boxFilters">
                        <div className="boxTitle" onClick={() => this.setState({ showUsers: !showUsers })}>
                          <p className="title">QUANTIDADE DE USUÁRIOS</p>
                          <FaAngleDown />
                        </div>
                        <div className={`boxBody ${this.state.showUsers ? `show` : `hide`}`}>
                          <Form>
                            <Form.Row>
                              <Col>
                                <Form.Check type="checkbox" label="1 usuário" name="users" value="1"checked={this.state.users.includes(1)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="2 usuários" name="users" value="2"checked={this.state.users.includes(2)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="5 usuários" name="users" value="5"checked={this.state.users.includes(5)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="10 usuários" name="users" value="10"checked={this.state.users.includes(10)} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                              <Col>
                                <Form.Check type="checkbox" label="20 usuários" name="users" value="20"checked={this.state.users.includes(20)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="60 usuários" name="users" value="60"checked={this.state.users.includes(60)} onChange={(e) => this.handleFilterChange(e)} />
                                <Form.Check type="checkbox" label="100 usuários" name="users" value="100"checked={this.state.users.includes(100)} onChange={(e) => this.handleFilterChange(e)}  />
                              </Col>
                            </Form.Row>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm={7}>
                      <div className="boxFilters" >
                      <div className="boxTitle" onClick={() => this.setState({ showAudience: !showAudience })}>
                        <p className="title">PÚBLICOS ADICIONAIS PARA O SEU MAILING</p>
                          <FaAngleDown />
                        </div>
                        <div className={`boxBody ${this.state.showAudience ? `show` : `hide`}`}>
                          <Form>
                            <Form.Row className="rowBorderBottom">
                              <Col sm={4}>
                                <p className="pNoMargin">Governo<br />e Terceiro Setor</p>
                              </Col>
                              <Col>
                                <Form.Check type="checkbox" label="Ilimitado" name="government" value="unlimited" checked={this.state.government.includes('unlimited')} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                            </Form.Row>
                            <Form.Row className="rowBorderBottom">
                              <Col sm={4} lg={4}>
                                <p className="pNoMargin">Influenciadores</p>
                              </Col>
                              <Col sm={4} lg={2}>
                                <Form.Check type="checkbox" label="Ilimitado" name="influencer" value="unlimited" checked={this.state.influencer.includes('unlimited')} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                              <Col sm={4} lg>
                                <Form.Check type="checkbox" label="Parcial" name="influencer" value="partial" checked={this.state.influencer.includes('partial')} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                            </Form.Row>
                            <Form.Row>
                              <Col sm={4} lg={4}>
                                <p className="pNoMargin">Imprensa LATAM</p>
                              </Col>
                              <Col sm={4} lg={2}>
                                <Form.Check type="checkbox" label="Ilimitado" name="latam" value="unlimited" checked={this.state.latam.includes('unlimited')} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                              <Col sm={4} lg>
                                <Form.Check type="checkbox" label="Parcial*" name="latam" value="partial" checked={this.state.latam.includes('partial')} onChange={(e) => this.handleFilterChange(e)} />
                              </Col>
                            </Form.Row>
                            <Form.Row>
                              <Col sm={8}>
                                <span>*.: A escolha do(s) País(es) disponíveis no seu Mailing será realizada na assinatura do contrato</span>
                              </Col>
                            </Form.Row>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5}>
                      <div className="boxFilters" id="boxMailingUnlimited">
                        <div className="boxTitle" onClick={() => this.setState({ showMailing: !showMailing })}>
                          <p className="title">MAILING DE IMPRENSA BRASIL</p>
                          <FaAngleDown />
                        </div>
                        <div className={`boxBody ${this.state.showMailing ? `show` : `hide`}`}>
                          <Form>
                            <Form.Row>
                              <Col>
                                <div className="boxTooltip">
                                  <Form.Check className="formTooltip" type="checkbox" label="Ilimitado" name="unlimited" value="1" checked={this.state.unlimited.includes(1)} onChange={(e) => this.handleFilterChange(e)} />
                                  <div className="divTooltip">
                                    <p data-tip="Dúvida">
                                      <FaQuestionCircle />
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="boxTooltip">
                                  <Form.Check className="formTooltip" type="checkbox" label="Parcial" name="unlimited" value="0" checked={this.state.unlimited.includes(0)} onChange={(e) => this.handleFilterChange(e)} />
                                  <div className="divTooltip">
                                    <p data-tip="Dúvida">
                                      <FaQuestionCircle />
                                    </p>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              </Col>
                            </Form.Row>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {this.state.unlimited.includes(0) &&
                    <Row>
                      <Col sm={12}>
                        <div className="boxFilters" id="boxFiltersBg">
                          <p className="title alignMobile">Filtros para segmentar de forma parcial o seu Mailing de Imprensa Brasil</p>
                          <p className="alignMobile" id="alignHideDesk">Escolha entre filtrar por regiões ou grupo de editorias e quantidade fixa por mês</p>
                          <Row>
                            <Col sm={3}>
                              <div className="boxTitle" onClick={() => this.setState({ showRegions: !showRegions })}>
                                <p className="title">REGIÕES</p>
                                <FaAngleDown />
                              </div>
                              <div className={`boxBody ${this.state.showRegions ? `show` : `hide`}`}>
                                <Form>
                                  <Form.Row>
                                    <Col>
                                      <p className="title marginTop">CONSULTE JORNALISTAS DE APENAS ALGUMA(S) REGIÃO(ÕES)</p>
                                      {/* <span>Escolha até duas regiões do País:</span> */}
                                    </Col>
                                  </Form.Row>
                                  <Form.Row>
                                    <Col>
                                      <Form.Check type="checkbox" label="1 região *" name="geographicMailing" value="1" checked={this.state.geographicMailing.includes(1)} onChange={(e) => this.handleFilterChange(e)}  />
                                      <Form.Check type="checkbox" label="2 regiões *" name="geographicMailing" value="2" checked={this.state.geographicMailing.includes(2)} onChange={(e) => this.handleFilterChange(e)}  />
                                      <span>*.: A escolha da(s) região(ões) disponíveis no seu Mailing será realizada na assinatura do contrato</span>
                                    </Col>
                                  </Form.Row>
                                </Form>
                              </div>
                              <div className="position">
                                <p>OU</p>
                              </div>
                            </Col>
                            <Col sm={9} className="bgMobileW">
                              <div className="positionMobile">
                                OU
                              </div>
                              <div className="boxBgMobile">
                                <Row>
                                  <Col sm={7} className="boxMailing">
                                    <div className="boxTitle" onClick={() => this.setState({ showEditorials: !showEditorials })}>
                                      <p className="title">EDITORIAS</p>
                                      <FaAngleDown />
                                    </div>
                                    <div className={`boxBody ${this.state.showEditorials ? `show` : `hide`}`}>
                                      <Form>
                                        <Form.Row>
                                          <Col>
                                            <p className="title marginTop">CONSULTE JORNALISTAS DE APENAS ALGUNS GRUPOS DE EDITORIAS</p>
                                            <span>Escolha os grupos do seu interesse:</span>
                                          </Col>
                                        </Form.Row>
                                        <Form.Row>
                                          <Col>
                                            <Form.Check type="checkbox" label="Cultura, Geek/Pop, Comida" name="editorialMailing" value="Cultura, Geek/Pop, Comida" checked={this.state.editorialMailing.includes('Cultura, Geek/Pop, Comida')}  onChange={(e) => this.handleFilterChange(e)}/>
                                            <Form.Check type="checkbox" label="Economia, Negócios e Marketing" name="editorialMailing" value="Economia, Negócios e Marketing" checked={this.state.editorialMailing.includes('Economia, Negócios e Marketing')} onChange={(e) => this.handleFilterChange(e)}/>
                                            <Form.Check type="checkbox" label="Educação, Política, Nacional, Sociedade" name="editorialMailing" value="Educação, Política, Nacional, Sociedade" checked={this.state.editorialMailing.includes('Educação, Política, Nacional, Sociedade')} onChange={(e) => this.handleFilterChange(e)}/>
                                            <Form.Check type="checkbox" label="Esportes" name="editorialMailing" value="Esportes" checked={this.state.editorialMailing.includes('Esportes')} onChange={(e) => this.handleFilterChange(e)}/>
                                            <Form.Check type="checkbox" label="Lifestyle, Moda/Beleza, Comportamento" name="editorialMailing" value="Lifestyle, Moda/Beleza, Comportamento" checked={this.state.editorialMailing.includes('Lifestyle, Moda/Beleza, Comportamento')}onChange={(e) => this.handleFilterChange(e)}/>
                                            <Form.Check type="checkbox" label="Tecnologia, Marketing, Recursos Humanos e Negócios" name="editorialMailing" value="Tecnologia, Marketing, Recursos Humanos e Negócios" checked={this.state.editorialMailing.includes('Tecnologia, Marketing, Recursos Humanos e Negócios')} onChange={(e) => this.handleFilterChange(e)}/>
                                          </Col>
                                        </Form.Row>
                                      </Form>
                                    </div>
                                  </Col>
                                  <Col sm={5} className="boxMailing">
                                    <p id="moreMobile">+</p>
                                    <div className="positionMore">
                                      <p>+</p>
                                    </div>
                                    <div className="boxTitle" onClick={() => this.setState({ showMonth: !showMonth })}>
                                      <p className="title">QUANTIDADE FIXA POR MÊS</p>
                                      <FaAngleDown />
                                    </div>
                                    <div className={`boxBody ${this.state.showMonth ? `show` : `hide`}`}>
                                      <Form>
                                        <Form.Row>
                                          <Col>
                                            <p className="title marginTop">CONSULTE UMA QUANTIDADE FIXA POR MÊS DE QUALQUER REGIÃO OU EDITORIA</p>
                                            <span>Selecione quantas opções quiser:</span>
                                          </Col>
                                        </Form.Row>
                                        <Form.Row>
                                          <Col sm={6}>
                                            <Form.Check type="checkbox" label="500/mês" name="journalistMailing" value="500" checked={this.state.journalistMailing.includes(500)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="750/mês" name="journalistMailing" value="750" checked={this.state.journalistMailing.includes(750)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="1.000/mês" name="journalistMailing" value="1000" checked={this.state.journalistMailing.includes(1000)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="1.500/mês" name="journalistMailing" value="1500" checked={this.state.journalistMailing.includes(1500)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="2.000/mês" name="journalistMailing" value="2000" checked={this.state.journalistMailing.includes(2000)} onChange={(e) => this.handleFilterChange(e)} />
                                          </Col>
                                          <Col sm={6}>
                                            <Form.Check type="checkbox" label="3.000/mês" name="journalistMailing" value="3000" checked={this.state.journalistMailing.includes(3000)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="5.000/mês" name="journalistMailing" value="5000" checked={this.state.journalistMailing.includes(5000)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="6.000/mês" name="journalistMailing" value="6000" checked={this.state.journalistMailing.includes(6000)} onChange={(e) => this.handleFilterChange(e)} />
                                            <Form.Check type="checkbox" label="12.000/mês" name="journalistMailing" value="12000" checked={this.state.journalistMailing.includes(12000)} onChange={(e) => this.handleFilterChange(e)} />
                                          </Col>
                                        </Form.Row>
                                      </Form>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  }
                </div>
                <div className="menuShowResult">
                  <Row>
                    <Col xs={7}>
                      <span>Exibindo:<br /> <b>{this.state.filteredSkus.length} resultado(s)</b></span>
                    </Col>
                    <Col xs={5}>
                      <button  onClick={() => this.setState({ showOverlay: !showOverlay })}>APLICAR</button>
                    </Col>
                  </Row>
                </div>
              </div>
          <Row>
            <Col xs={12}>
              <Form className="orderPlans" id="formOrder">
                <Form.Control as="select" value={this.state.ordernation} onChange={(e) => this.handleOrdernationChange(e)}>
                  <option value="lowestPrice">Do menor preço para o maior</option>
                  <option value="highestPrice">Do maior preço para o menor</option>
                  <option value="highestSubmissions">Pela maior quantidade de disparos de e-mail</option>
                  <option value="lowestSubmissions">Pela menor quantidade de disparos de e-mail</option>
                  <option value="longerInstallments">Pelo menor tempo de contrato</option>
                </Form.Control>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <div ref={this.resultRef} id="result">
        <div className="boxGray" id="boxGrayInFilters">
          <div className="container">
            <Row>
              <Col sm={8}>
                  <h3 className="title">Veja os planos disponíveis</h3>
              </Col>
              <Col sm={4}>
                <Form className="orderPlans">
                      <Form.Control as="select" value={this.state.ordernation} onChange={(e) => this.handleOrdernationChange(e)}>
                        <option value="lowestPrice">Do menor preço para o maior</option>
                        <option value="highestPrice">Do maior preço para o menor</option>
                        <option value="highestSubmissions">Pela maior quantidade de disparos de e-mail</option>
                        <option value="lowestSubmissions">Pela menor quantidade de disparos de e-mail</option>
                        <option value="longerInstallments">Pelo menor tempo de contrato</option>
                      </Form.Control>
                </Form>
                {/* <Form className="orderPlans">
                  <Form.Group as={Row}>
                    <Col sm={4}>
                      <Form.Label className="labelOrder">
                        Ordernar por
                      </Form.Label>
                      </Col>
                    <Col sm={8}>
                      <Form.Control as="select" defaultValue="Selecione uma opção...">
                        <option selected>Selecione uma opção</option>
                        <option>Do menor preço para o maior</option>
                        <option>Do maior preço para o menor</option>
                        <option>Pela maior quantidade de disparos de e-mail</option>
                        <option>Pela menor quantidade de disparos de e-mail</option>
                        <option>Pelo menor tempo de contrato</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Form> */}
              </Col>
            </Row>
          </div>
        </div>
        <div className="container">
          <Row>
            <Col>
                  <p>Exibindo {this.state.filteredSkus.length} resultado(s)<br /><br /></p>
            </Col>
          </Row>
        </div>
        {this.state.filteredSkus.map((sku, index) => (
          <ResultFilter key={index} sku={sku} phone={this.state.phone}/>
        ))}
      </div>
      </>
    );
  }
}  

export default withRouter(Filters);