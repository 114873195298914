import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ContractKey from '../ContractKey';

import './styles.scss';

export default class SummaryKey extends React.Component {
  render() {
    return (
      <>   
        <div className="container">
          <Row>
            <Col sm={6}>
              <h4 className="title">DASHBOARD</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxQuestions">
                      <p className="title">Quantidade de usuários</p>
                      <p className="answer">usuário(s) com acesso à plataforma</p>
                      <p className="answerGreenLight"> usuário(s) cadastrado(s)</p>
                      <hr />
                    </div>
                    <div className="boxQuestions">
                      <p className="title">Quantidade de disparos de e-mails</p>
                      <p className="answer">15 mil disparos/mês</p>
                      <p className="answerGreenLight"> 10 mil disparos realizados no mês</p>
                      <p className="answerGreenLight"> Faltam <b>X</b> dias para restaurar seus disparos</p>
                      <hr />
                    </div>
                    <div className="boxQuestions">
                      <p className="title">Quantidade de jornalistas/mês</p>
                      <p className="answerGreenLight"> 500 jornalistas consultados no mês</p>
                      <p className="answerGreenLight"> Faltam <b>X</b> dias para restaurar suas consultas a jornalistas</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <h4 className="title">INFORMAÇÕES TÉCNICAS</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxQuestions">
                      <p className="title">Domínio(s) para disparo de e-mails</p>
                      <p className="answer">exemplo.com.br</p>
                      <hr />
                    </div>
                    <div className="boxQuestions">
                      <p className="title">Configuração SPF</p>
                      <p className="answer">Não realizada</p>
                      <hr />
                    </div>
                    <div className="boxQuestions">
                      <p className="title">Configuração DKIM</p>
                      <p className="answer">Não realizada</p>
                      <hr />
                    </div>
                    <div className="boxQuestions">
                      <p className="title">Registro CNAME</p>
                      <p className="answer">Não realizado</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <ContractKey />
      </>
    );
  }
}  