import React from 'react';

import PanelKeyAccount from '../../components/PanelKeyAccount';

import './styles.css';

export default class SummaryKeyAccount extends React.Component {
    render() {
      return (
        <>
          <PanelKeyAccount /> 
        </>
      );
    }
}  