import axios from 'axios';
import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class Contract extends React.Component {
  state = {
    showModal: false,
    showOverlay: false,
    signers: [
      '',
      ''
    ],
    manager: '',
    witnesses: [
      '',
      ''
    ],
    cc: '',
    docx: '',
    html: '',
    
  }

  componentDidMount(){
    this.getInfos();
  }

  getInfos = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sku = urlParams.get('sku');
    let contract = window.processSKU(sku);

    let infos = window.getURLparams();

    let witnesses = this.state.witnesses;
    witnesses[0] = infos.witness0;

    let manager = infos.manager;
    let signers = [
      !!infos.form.person ? infos.form.person.email : infos.form.partners[0]?.email,
      !!infos.form.person ? '' : infos.form.partners[1]?.email
    ];

    this.setState({
      docx: contract.docx,
      html: contract.html,
      witnesses,
      manager,
      signers
    });
  }

  sendToSign = () => {
    let data = {
      signers: this.state.signers,
      manager: this.state.manager,
      witnesses: this.state.witnesses,
      cc: this.state.cc
    }

    window.processContract(data);
  }

  handleSignerInputChange = (e, index) => {
    let value = e.target.value;
    let signers = this.state.signers;

    signers[index] = value;

    this.setState({ signers });
  }

  handleWitnessesInputChange = (e, index) => {
    let value = e.target.value;
    let witnesses = this.state.witnesses;

    witnesses[index] = value;

    this.setState({ witnesses });
  }

  handleCcInputChange = e => {
    let value = e.target.value;

    this.setState({ cc: value });
  }

  downloadDocx = () => {
    window.open(this.state.docx);
  }

  render() {
    const { showModal, showOverlay } = this.state
    return (
      <>
      {showOverlay && (
        <div id="overlay"></div>
      )}
      { showModal && (
        <>
          <div className="modal">
            <button className="closeModal" onClick={() => this.setState({ showModal: !showModal,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title text-center">RESPONSÁVEIS POR ASSINAR DIGITALMENTE</p>
                </Col>
              </Row>
              <Form>
                <Form.Row>
                  <Col sm={12}>
                    <p className="title">NOME EMPRESA</p>
                    <span>Confirme ou altere os e-mails que devem assinar o termo de uso</span>
                    <Form.Group>
                      <Form.Control type="text" placeholder="responsavel1@empresa.com.br" value={this.state.signers[0]} onChange={(e) => this.handleSignerInputChange(e, 0)} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control type="text" placeholder="responsavel1@empresa.com.br" value={this.state.signers[1]} onChange={(e) => this.handleSignerInputChange(e, 1)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <p className="title">I'MAX</p>
                    <Form.Group>
                      <Form.Control type="text" value={this.state.manager} readOnly />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <p className="title">TESTEMUNHAS</p>                      
                    <Form.Control type="text" value={this.state.witnesses[0]} readOnly />
                    <span style={{marginBottom: 3, marginTop: 10}}>Opcional</span>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Adicione um e-mail para ser testemunha" value={this.state.witnesses[1]} onChange={(e) => this.handleWitnessesInputChange(e, 1)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <p className="title">CÓPIA DO CONTRATO</p>
                    <span style={{marginBottom: 3, marginTop: 10}}>Opcional</span>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Adicione um e-mail para receber uma cópia" value={this.state.cc} onChange={(e) => this.handleCcInputChange(e)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <button type="button" onClick={() => this.sendToSign()}>Enviar para assinatura</button>
              </Form>
            </div>
          </div>
        </>
      )}
      <div className="container">
        <div className="contract">
          <Row>
            <Col sm={12}>
              <h3 className="title">TERMO DE USO DO MAILING I'MAX</h3>
              <h5 className="title">Leia na íntegra abaixo e depois assine digitalmente o arquivo para ativar seu plano</h5>
              <hr />
              <button id="btnSignature" onClick={() => this.setState({ showModal: !showModal,  showOverlay: !showOverlay })}>Assinar digitalmente</button>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="boxContract">
                <p>Quer conferir dados antes de assinar?</p>
                <button onClick={() => window.location.href='/contratacao'}>Ver ficha administrativa</button>
              </div>
            </Col>
            <Col sm={4}>
              <div className="boxContract">
                <p>Deseja revisar os itens do contrato?</p>
                <button onClick={() => this.downloadDocx()}>Baixar contrato em DOC</button>
              </div>
            </Col>
            <Col sm={4}>
              <div className="boxContract">
                <p>Precisa adaptar o seu contrato?</p>
                <p>ENTRE EM CONTATO COM JÚLIA GARCIA PARA ENVIAR QUALQUER SUGESTÃO DE ALTERAÇÃO</p>
                <p>julia.garcia@i-maxpr.com | +55 11 95836-3764</p>
              </div>
            </Col>
          </Row>
        </div>
        <div id="contract">
          <div className="content" dangerouslySetInnerHTML={{__html: this.state.html}}></div>
        </div>
      </div>
      </>
    );
  }
}  