import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';
import { FaAngleDown } from 'react-icons/fa';

import ReactCodeInput from 'react-verification-code-input';
import { 
  usersToString, 
  contractToString, 
  regionToString, 
  toStringPrice, 
  toStringQty,
  journalistsToString,
  governmentToString,
  influencerToString,
  latamToString,
  editorialsToString
} from '../../useful/conversions';

import './styles.scss';

export default class ResultFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showOverlay: false,
      showModalCode: false,
      showMoreDetails: false,
      resCode: null,
      SMSSent: null
    }
  }

  proposalDownload = () => {
    if(!this.state.resCode) return;
    let sku = this.props.sku.sku;
    let detail = window.processSKU(sku);
    if(detail){
      window.open(detail.pdf);
      this.setState({ showModalCode: false, showOverlay: false });
    } else {
      alert('Erro ao obter proposta. Por favor, tente novamente.');
    }
  }

  sendSMS = (sku) => {
    window.selectedSKU(sku, 1);
    this.setState({ SMSSent: true });
    setTimeout(
      function() {
          this.setState({ SMSSent: null });
      }
      .bind(this),
      2000
    );
  }

  handleHirePlan = () => {
    window.selectedSKU(this.props.sku.sku, 0);
    this.setState({ showModal: true, showOverlay: true });
  }

  handleCodeInput = (c) => {
    let resCode = null;

    if(c.length === 6){
      resCode = window.isCodeValid(c);
    }

    this.setState({ resCode });
  }
  
  render() {
    const { showModal, showOverlay, showModalCode, showMoreDetails } = this.state
    return (
      <>
      {showOverlay && (
        <div id="overlay"></div>
      )}
      { showModal && (
        <>
          <div className="modal text-center" id="modalDownload">
            <button className="closeModal" onClick={() => this.setState({ showModal: !showModal,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">VEJA OS PRÓXIMOS PASSOS ANTES DE BAIXAR A SUA PROPOSTA:</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ul>
                    <li>
                      <p>
                        1) Fazer o download da sua proposta em PDF.
                      </p>
                    </li>
                    <li>
                      <p>
                        2) Acessar o link de fechamento de contrato que estará na sua proposta.
                      </p>
                    </li>
                    <li>
                      <p>
                        3) Assinar o seu contrato digitalmente.
                      </p>
                    </li>
                    <li>
                      <p>
                        4) Definir os primeiros detalhes de uso no Onboarding.
                      </p>
                    </li>
                  </ul>
                </Col>
              </Row>
              <button onClick={() => this.setState({ showModalCode: !showModalCode, showModal: !showModal})}>Continuar para baixar proposta</button>
            </div>
          </div>
        </>
      )}
      { showModalCode && (
        <>
          <div className="modal text-center" id="modalCode">
            <button className="closeModal" onClick={() => this.setState({ showModalCode: !showModalCode,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Digite o código que enviamos para o WhatsApp de {this.props.phone}:</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ReactCodeInput type="text" onChange={(c) => this.handleCodeInput(c)}/>
                </Col>
              </Row>
              {this.state.resCode !== null &&
                <Row>
                  <Col>
                    {!this.state.resCode &&
                      <p className="textInvalidCode">Código inválido</p>
                    }
                  </Col>
                </Row>
              }
              {this.state.SMSSent === true &&
                <Row>
                  <Col>
                    <p className="textSMSSent">SMS enviado</p>
                  </Col>
                </Row>
              }
              <Row>
                <Col sm={12}>
                  <button id="btnDownloadModal" onClick={() => this.proposalDownload()}>Baixar proposta sem compromisso</button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button className="btnModal" onClick={() => this.sendSMS(this.props.sku.sku)}>Não usa o WhatsApp? Clique aqui para receber o código por SMS.</button>
                </Col>
                <Col>
                  <button className="btnModal" onClick={() => window.location.href='https://site.i-max.imaxinformatica.com.br/solicite-sua-proposta/'}>Não reconhece esse número? Clique aqui para reiniciar o processo.</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      <div className="container">
        {this.props.sku &&
          <Row>
            <Col sm={12}>
              <div className="boxResultFilter" data-sku={this.props.sku.sku}>
                {this.props.sku.sku === 720 &&
                  <div id="divAbsolute">
                    Recomendado
                  </div>
                }
                <Row>
                  <Col sm={12} md={3}>
                    <p className="title">MAILING DE IMPRENSA</p>
                    <ul>
                      <li><p><b>Geográfico:</b> <br />{regionToString(this.props.sku.geographicMailing)}</p></li>
                      <li><p><b>Editorias:</b> <br />{editorialsToString(this.props.sku.editorialMailing, this.props.sku.geographicMailing)}</p></li>
                      <li><p><b>Jornalistas:</b><br />{journalistsToString(this.props.sku.journalistMailing, this.props.sku.geographicMailing)}</p></li>
                    </ul>
                  </Col>
                  <Col sm={12} md={3}>
                    <p className="title">PÚBLICOS ADICIONAIS</p>
                    <ul>
                      <li><p><b>Governo e Terceiro Setor:</b> <br />{governmentToString(this.props.sku.government)}</p></li>
                      <li><p><b>Influenciadores:</b> <br />{influencerToString(this.props.sku.influencer)}</p></li>
                      <li><p><b>Imprensa LATAM:</b> <br />{latamToString(this.props.sku.latam)}</p></li>
                    </ul>
                  </Col>
                  <Col sm={12} md={3} className="bgWhite">
                    <div className="boxPaddingTop">
                      <p className="title">ENVIO DE E-MAILS</p>
                      {this.props.sku.submissions === 0 &&
                        <>
                          <span className="textGreen">zero</span>
                          <p className="textGreen">disparos/mês</p>
                        </>
                      }
                      {this.props.sku.submissions > 0 &&
                        <>
                          <span className="textGreen">{toStringQty(this.props.sku.submissions)}</span>
                          <p className="textGreen">disparos/mês</p>
                        </>
                      }
                      <ul id="ulUsers">
                        <li><p><b>Usuários: </b> <br />{usersToString(this.props.sku.users)}</p></li>
                        <li><p><b>Contrato: </b> <br />{contractToString(this.props.sku.installments)}</p></li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="boxPaddingTop">
                    <p className="title">PREÇO</p>
                      <p id="priceTotal"><span>{toStringPrice(this.props.sku.valuePerMonth)}</span>/mês</p>
                      <p>Valor total: {toStringPrice(this.props.sku.price)}</p>
                      <button onClick={() => this.handleHirePlan()}>Contratar plano</button>
                    </div>
                  </Col>
                </Row>
                <hr />
                <a className="seeMoreDetails" href="#" onClick={(e) => {
                  e.preventDefault();
                  let n = !showMoreDetails;
                  this.setState({ showMoreDetails: n });
                }}>Ver mais detalhes <FaAngleDown className={showMoreDetails ? 'icUp' : 'icDown'} /></a>
                {showMoreDetails &&
                  <div className="boxMoreDetails">
                    <p>
                      <b>Forma de pagamento</b><br />
                      <div className="content" dangerouslySetInnerHTML={{__html: this.props.sku.paymentoptions}}></div>
                    </p>
                    <br />
                    <p>
                      <b>Como funciona</b><br />
                      <div className="content" dangerouslySetInnerHTML={{__html: this.props.sku.desc}}></div>
                    </p>
                    <br />
                    <p>
                      <b>Atenção para</b><br />
                      <div className="content" dangerouslySetInnerHTML={{__html: this.props.sku.caveat}}></div>
                      
                    </p>
                  </div>
                }
              </div>
            </Col>
          </Row>
        }
      </div>
      </>
    );
  }
}  