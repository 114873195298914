import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Filters from '../../components/Filters';

import './styles.css';

export default class showFilters extends React.Component {
    render() {
      return (
        <>
          <Filters /> 
        </>
      );
    }
}  