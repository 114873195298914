import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import ReactCodeInput from 'react-verification-code-input';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class SummaryAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasForm: false,
      showOverlay: false,
      showModalKey: false,
      showModalCode: false
    }
  }

  next = () => {
    window.location.href=`/key-account`;
  }
  
  render() {
    const { showOverlay, showModalKey, showModalCode } = this.state
    return (
      <>   
      <div className="container">
        <div className="summary">
          <Row>
            <Col sm={12}>
              <h3 className="title">PAINEL ADMINISTRATIVO I'MAX</h3>
              <h5 className="title">Realize as <b>configurações iniciais</b> para <b>ativar a sua conta</b></h5>
            </Col>
          </Row>
        </div>
      </div>
      <div className="boxGray boxGraySummaryAccount">
        <div className="container">
          <Row>
            <Col sm={12}>
              <h4 className="title">COMO VOCÊ PREFERE ASSINAR O SEU TERMO DE USO?</h4>
            </Col>
          </Row>
          <div className="boxResultAccount">
            <Row>
              <Col sm={6}>
                <div className="boxQuestions">
                  <p className="title">Mailing de Imprensa Brasil</p>
                  <p className="answer">Parcial com 1 região do País</p>
                  <hr />
                </div>
                <div className="boxQuestions">
                  <p className="title">Públicos adicionais</p>
                  <p className="answer">Mailing Governo e Terceiro Setor: <span className="spanWhite">SEM ACESSO</span></p>
                  <p className="answer">Mailing de Influenciadores: <span className="spanWhite">SEM ACESSO</span></p>
                  <p className="answer">Mailing de Imprensa LATAM: <span className="spanWhite">3 PAÍSES</span></p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="boxQuestions">
                  <p className="title">Tempo de uso</p>
                  <p className="answer">6 meses</p>
                  <hr />
                </div>
                <div className="boxQuestions">
                  <p className="title">Quantidade de usuários</p>
                  <p className="answer">1 usuário(s) com acesso à plataforma</p>
                  <hr />
                </div>
                <div className="boxQuestions">
                  <p className="title">Quantidade de disparos de e-mails</p>
                  <p className="answer">15 mil disparos/mês</p>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col sm={6}>
              <h4 className="title">ATIVAÇÃO E PAGAMENTO</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxConfiguration">
                      <p className="titleSection">Defina a data de ativação do contrato</p>
                      <Form>
                        <Row>
                          <Col sm={3} xs={4}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Dia</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                                <option>21</option>
                                <option>22</option>
                                <option>23</option>
                                <option>24</option>
                                <option>25</option>
                                <option>26</option>
                                <option>27</option>
                                <option>28</option>
                                <option>29</option>
                                <option>30</option>
                                <option>31</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <p>/</p>
                          <Col sm={3} xs={4}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Mês</option>
                                <option>Janeiro</option>
                                <option>Fevereiro</option>
                                <option>Março</option>
                                <option>Abril</option>
                                <option>Maio</option>
                                <option>Junho</option>
                                <option>Julho</option>
                                <option>Agosto</option>
                                <option>Setembro</option>
                                <option>Outubro</option>
                                <option>Novembro</option>
                                <option>Dezembro</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <p>/ 2021</p>
                        </Row>
                      </Form>
                      <span>
                        Essa é a data que a plataforma estára liberada para uso e o dia de vencimento da primeira mensalidade ou da fatura única para contratos com a opção de pagamento à vista.
                        O vencimento dos próximos boletos será o mesmo dia da primeira cobrança, só que nos meses subsequentes, até o vencimento do contrato. O sistema envia o boleto e NF com até 25 dias de antecedência.
                      </span>
                    </div>
                    <div className="boxConfiguration marginTop">
                      <p className="titleSection">Responsável financeiro</p>
                      <span>
                        Dados de pagamento, notas fiscais e outras informações financeiras serão enviadas para o(s) e-mail(s) cadastrado(s) na assinatura do contrato
                      </span>
                      <Form>
                        <Form.Group>
                          <Form.Control type="email" value="nome1@empresa.com.br, nome2@empresa.com.br" readOnly />
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="boxConfiguration marginTop">
                      <p className="titleSection" style={{marginBottom: 5}}>Forma de pagamento</p>
                      <p>Valor total: <b>R$ 0.000,00</b></p>
                      <p>Pagamento parcelado: <b>6 x R$ 0.000,00</b></p>
                      <p>Pagamento à vista: <b>R$ 0.000,00</b></p>
                      <Form>
                        <Row>
                          <Col sm={8}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Selecione a forma de pagamento</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={6}>
              <h4 className="title">DOMÍNIO PARA ENVIO DE E-MAILS</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxConfiguration">
                      <p className="titleSection">Defina o domínio vinculado à sua conta</p>
                      <Form>
                        <Form.Group>
                          <Form.Control type="email" placeholder="Digite a URL do seu domínio de disparos de e-mail" />
                        </Form.Group>
                      </Form>
                      <div className="textCenter">
                        <p>A sua empresa não possui domínio?</p>
                        <button className="btnWhite" onClick={() => this.setState({ showModalKey: !showModalKey,  showOverlay: !showOverlay })}>Clique aqui</button>
                      </div>
                      <span>
                        <b style={{fontSize: 16}}>Por que é necessário cadastrar o domínio da sua empresa?</b><br />
                        O domínio é utilizado como remetente dos e-mails disparados pelos usuários e identifica a sua empresa como proprietária do conteúdo enviado aos seus destinatários.
                        Esse cadastro é fundamental para atribuir autoridade pelos seus releases e melhorar a eficiência dos seus disparos.
                      </span>
                    </div>
                    <div className="boxConfiguration" style={{marginTop: 15}}>
                      <p className="titleSection">ATENÇÃO:</p>
                      <span>
                        A mudança do domínio corporativo durante o período de vigência do seu contrato implicará na perda de todo o histórico da sua conta ou na cobrança de custos extras pela migração do domínio.
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <h4 className="title">REGIÃO DO MAILING DE IMPRENSA BRASIL</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxConfiguration">
                      <p className="titleSection">Qual será a região do País utilizada no seu contrato?</p>
                      <Form>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Selecione uma região</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                      <span>
                        Essa configuração não pode ser alterada após a ativação da conta. Será necessário cancelar o plano vigente e iniciar um novo contrato para alterar a região escolhida.
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <h4 className="title">PAÍSES DO MAILING DE IMPRENSA LATAM</h4>
              <div className="boxResultAccount">
                <Row>
                  <Col sm={12}>
                    <div className="boxConfiguration">
                      <p className="titleSection">Quais serão os países utilizados no seu contrato?</p>
                      <Form>
                        <Row>
                          <Col sm={4}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Selecione um País</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Selecione um País</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group>
                              <Form.Control as="select">
                                <option selected disabled>Selecione um País</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                      <span>
                        Essa configuração não pode ser alterada após a ativação da conta. Será necessário cancelar o plano vigente e iniciar um novo contrato para alterar os países escolhidos.
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button id="seeSummary" onClick={() => this.setState({ showModalCode: !showModalCode,  showOverlay: !showOverlay })}>Salvar configurações e ativar conta</button>
            </Col>
          </Row>
        </div>
      </div>
      {showOverlay && (
        <div id="overlay"></div>
      )}
      { showModalKey && (
        <>
          <div className="modal text-center modalGreen" id="modalConfiguration">
            <button className="closeModal" onClick={() => this.setState({ showModalKey: !showModalKey,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Não possui domínio corporativo?</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p style={{marginTop: 15}}>
                    Se você não possui um domínio corporativo, o I'Max pode criar um domínio coletivo para o seu disparo de releases usando um e-mail de trabalho.
                  </p>
                  </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form>
                    <Form.Group>
                      <Form.Control type="email" placeholder="Digite o e-mail de trabalho" />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <span style={{marginTop: 15}}>O remetente dos disparos de releases e login da conta será:</span>
                  <Form>
                    <Form.Group>
                      <Form.Control type="email" value="nome.exemplo=emailgratuito.com@mediaroom.press" readOnly />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className="title">IMPORTANTE:</p>
                  <ul>
                    <li>
                      <span>
                        1) Copie o remetente gerado porque ele será o seu login na plataforma.
                      </span>
                    </li>
                    <li>
                      <span>
                        2) Caso respondam releases disparados pelo remetente, essas respostas serão automaticamente enviadas para o e-mail de trabalho.
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Row>
                    <Col sm={12}>
                      <Form.Check type="checkbox" id="agree" label="Concordo que contas com domínio coletivo possuem APENAS um usuário." value="Concordo" />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm={12}>
                      <Form.Check type="checkbox" id="aware" label="Estou ciente que, se quises cadastrar meu domínio corporativo, eu perderei o histórico da conta ou terei que pagar o custo de migração de domínio." value="Ciente" />
                    </Col>
                  </Form.Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <button>Continuar com domínio coletivo do I'Max</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      { showModalCode && (
        <>
          <div className="modal text-center modalGreen" id="modalCodeKey">
            <button className="closeModal" onClick={() => this.setState({ showModalCode: !showModalCode,  showOverlay: !showOverlay })}><RiCloseCircleLine /></button>
            <div className="container">
              <Row>
                <Col sm={12}>
                  <p className="title">Digite o código que enviamos para o WhatsApp de {this.props.phone}:</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ReactCodeInput type="text" onChange={(c) => this.handleCodeInput(c)}/>
                </Col>
              </Row>
              {/*{this.state.resCode !== null &&
                <Row>
                  <Col>
                    {!this.state.resCode &&
                      <p className="textInvalidCode">Código inválido</p>
                    }
                  </Col>
                </Row>
              }
              {this.state.SMSSent === true &&
                <Row>
                  <Col>
                    <p className="textSMSSent">SMS enviado</p>
                  </Col>
                </Row>
              }*/}
              <Row>
                <Col sm={12}>
                  <button id="activeAccount">Ativar conta</button>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p><b>ATENÇÃO: </b>após a ativação da conta, apenas o campo de Responsável Financeiro poderá ser editado. Todas as outras configurações iniciais não poderão ser alteradas durante o tempo de contrato.</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button className="btnModal" onClick={() => this.sendSMS(this.props.sku.sku)}>Não usa o WhatsApp? Clique aqui para receber o código por SMS.</button>
                </Col>
                <Col>
                  <button className="btnModal" onClick={() => window.location.href='https://site.i-max.imaxinformatica.com.br/solicite-sua-proposta/'}>Não reconhece esse número? Clique aqui para solicitar sua proposta.</button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      </>
    );
  }
}  