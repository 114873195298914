import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './styles.scss';

import logoHeader from '../../assets/images/logo.png';

export default class Header extends React.Component {
    render() {
      return (
        <>
        <header>
          <div className="container">
            <Row>
              <Col className="header-col-center">
                <a href="/"><img src={logoHeader} alt="I'Max" /></a>
              </Col>
            </Row>
          </div>
        </header>
        </>
      );
    }
}  