import React from 'react';
import axios from 'axios';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { cepMask, cpfMask, cnpjMask, phoneMask } from '../../useful/masks';
import { cpfValidate, emailValidate, cnpjValidate } from '../../useful/validates';
import { priceMask, toStringQty, usersToString, latamToString, influencerToString, governmentToString } from '../../useful/conversions';

import './styles.scss';

export default class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasForm: false,

      value: '',
      installments: '',
      users: '',
      submissions: '',
      mailing: '',
      latam:  '',
      government:  '',
      influencer:  '',

      showCNPJ: true,
      showCPF: false,
      company: {
        cnpj: '',
        ie: '',
        ccm: '',
        companyName: '',
        tradingName: '',
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        city: '',
        state: '',
        neigh: '',
        website: ''
      },
      checkKeyAccount: false,
      checkIe: false,
      keyAccount: {
        name: '',
        email: '',
        phone: ''
      },
      partners: [
        {
          name: '',
          email: '',
          phone: '',
          rg: '',
          cpf: ''
        },
        {
          name: '',
          email: '',
          phone: '',
          rg: '',
          cpf: ''
        }
      ],
      financialEmail: '',
      ITUser: {
        name: '',
        email: '',
        phone: ''
      },
      politicalUser: {
        name: '',
        email: '',
        phone: ''
      },
      person: {
        name: '',
        email: '',
        phone: '',
        cpf: '',
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        city: '',
        state: '',
        neigh: ''
      }
    }
  }

  componentDidMount() {
    this.getData();
    this.getSku();
  }

  getData = () => {
    let data = window.getURLparams();
    let hasForm = !!data.form;

    this.setState({ hasForm });
    
    if(hasForm){
      if(!!data.form.person){
        this.setState({ person: data.form.person });
      } else {
        console.log(data.form.partners);
        this.setState({
          company: data.form.company,
          checkKeyAccount: data.form.checkKeyAccount,
          checkIe: data.form.checkIe,
          keyAccount: data.form.keyAccount,
          partners: data.form.partners,
          financialEmail: data.form.financialEmail,
          ITUser: data.form.ITUser,
          politicalUser: data.form.politicalUser,
        });
      }
    }
  }

  getSku = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sku = urlParams.get('sku');
    console.log(sku);

    axios.get(`https://www.i-maxpr.com/beowulf/API/getPlans.php?key=666999&sku=${sku}`)
      .then(res => {
        let data = res.data.skus[0][0];
        let mailing = '';
        console.log(data);

        if(data.unlimited === 1){
          mailing = 'Ilimitado';
        } else {
          mailing  = 'Parcial';
          mailing += data.editorialMailing === 0 ? '' : ` com editorias de ${data.editorialMailing}`;
          mailing += data.journalistMailing === 0 ? '' : ` + ${toStringQty(data.journalistMailing)} jornalistas/mês`;
        }

        this.setState({
          value: `${data.installments} x ${priceMask(data.valuePerMonth.toString())} (Valor total de ${priceMask(data.price.toString())})`,
          installments: `${data.installments} ${data.installments === 1 ? 'mês' : 'meses'}`,
          users: usersToString(data.users),
          submissions: `${toStringQty(data.submissions)} disparos/mês`,
          mailing,
          government: data.government === 'Ilimitado' ? 'Ilimitado' : governmentToString(data.government),
          influencer: data.influencer === 'Ilimitado' ? 'Ilimitado' : influencerToString(data.influencer),
          latam: data.latam === 'Ilimitado' ? 'Ilimitado' : latamToString(data.latam)
        });
      });
  }

  next = () => {
    const validation = this.validate();
    if(!validation) return;

    let data;

    let keyAccount = !this.state.checkKeyAccount ? this.state.keyAccount : {
      name: this.state.partners[0].name,
      email: this.state.partners[0].email,
      phone: this.state.partners[0].phone
    };

    if(this.state.showCNPJ){
      data = {
        company: this.state.company,
        checkKeyAccount: this.state.checkKeyAccount,
        checkIe: this.state.checkIe,
        keyAccount,
        partners: this.state.partners,
        financialEmail: this.state.financialEmail,
        ITUser: this.state.ITUser,
        politicalUser: this.state.politicalUser
      };
    } else {
      data = {
        person: this.state.person
      }
    }

    window.processForm(data);
  }

  validate = () => {
    if(this.state.showCPF){
      if(this.state.person.name.length <2){
        alert('Por favor, preencha o nome');
        return false;
      }

      if(!emailValidate(this.state.person.email)){
        alert('Por favor, preencha o e-mail corretamente');
        return false;
      }

      if(this.state.person.phone.length < 15){
        alert('Por favor, preencha o telefone corretamente');
        return false;
      }
      
      if(!cpfValidate(this.state.person.cpf)){
        alert('Por favor, verifique o CPF informado');
        return false;
      }

      if(this.state.person.zipCode.length < 9){
        alert('Por favor, preencha o cep corretamente');
        return false;
      }

      if(this.state.person.address.length < 2){
        alert('Por favor, preencha o endereço corretamente');
        return false;
      }

      if(this.state.person.city.length < 2){
        alert('Por favor, preencha a cidade corretamente');
        return false;
      }

      if(this.state.person.state.length < 2){
        alert('Por favor, preencha a UF corretamente');
        return false;
      }

      if(this.state.person.neigh.length < 2){
        alert('Por favor, preencha o bairro corretamente');
        return false;
      }
    } else {
      if(!cnpjValidate(this.state.company.cnpj)){
        alert('Por favor, preencha corretamente o CNPJ da empresa');
        return;
      }

      if(this.state.company.companyName.length < 2) {
        alert('Por favor, preencha a razão social da empresa');
        return false;
      }

      if(this.state.company.zipCode.length < 9){
        alert('Por favor, preencha o cep corretamente');
        return false;
      }

      if(this.state.company.address.length < 2){
        alert('Por favor, preencha o endereço corretamente');
        return false;
      }

      if(this.state.company.city.length < 2){
        alert('Por favor, preencha a cidade corretamente');
        return false;
      }

      if(this.state.company.state.length < 2){
        alert('Por favor, preencha a UF corretamente');
        return false;
      }

      if(this.state.company.neigh.length < 2){
        alert('Por favor, preencha o bairro corretamente');
        return false;
      }

      // partners validation
      if(this.state.partners[0].name.length < 2){
        alert('Por favor, preencha corretamente o nome do(a) sócia(a) administrador(a) ou responsável legal');
        return false;
      }

      if(!emailValidate(this.state.partners[0].email)){
        alert('Por favor, preencha corretamente o e-mail do(a) sócia(a) administrador(a) ou responsável legal');
        return false;
      }

      if(this.state.partners[0].phone.length < 15){
        alert('Por favor, preencha corretamente o telefone celular do(a) sócia(a) administrador(a) ou responsável legal');
        return false;
      }

      if(this.state.partners[0].rg.length < 5){
        alert('Por favor, preencha corretamente o RG do(a) sócia(a) administrador(a) ou responsável legal');
        return false;
      }
      
      if(!cpfValidate(this.state.partners[0].cpf)){
        alert('Por favor, preencha corretamente o CPF do(a) sócia(a) administrador(a) ou responsável legal');
        return false;
      }

      // finacial e-mail
      if(!emailValidate(this.state.financialEmail)){
        alert('Por favor, preencha corretamente o e-mail do responsável financeiro');
        return false;
      }

      let fields = [
        {
          name: 'keyAccount',
          label: 'Key Account',
          required: !this.state.checkKeyAccount,
        },
        {
          name: 'ITUser',
          label: 'Usuário(a) do Departamento de TI',
          required: false,
        },
        {
          name: 'politicalUser',
          label: 'Usuário(a) do Departamento Político',
          required: false,
        }
      ];

      fields.map(f => {
        if(f.required){
          if(this.state[f.name].name.length < 2){
            alert(`Por favor, preencha o nome em ${f.label}`);
            return false;
          }

          if(!emailValidate(this.state[f.name].email)){
            alert(`Por favor, preencha o e-mail corretamente em ${f.label}`);
            return false;
          }

          if(this.state[f.name].phone.length < 15){
            alert(`Por favor, preencha o telefone celular corretamente em ${f.label}`);
            return false;
          }

        }
      });
    }

    return true;
  }

  handleCheckKeyAccountChange = e => {
    let checked = e.target.checked;
    this.setState({ checkKeyAccount: checked });
  }

  handleCheckIe = e => {
    let checked = e.target.checked;

    this.setState({ checkIe: checked });
  }

  handlePersonInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'phone') value = phoneMask(value);
    if(name === 'cpf') value = cpfMask(value);
    if(name === 'zipCode'){
      value = cepMask(value);
      if(value.length === 9) this.getAddress(value, 'person');
    }

    this.setState({ person: { ...this.state.person, [name]: value} });
  }

  handleKeyAccountInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'phone') value = phoneMask(value);

    this.setState({ keyAccount: { ...this.state.keyAccount, [name]: value} });
  }

  handlePoliticalUserInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'phone') value = phoneMask(value);

    this.setState({ politicalUser: { ...this.state.politicalUser, [name]: value} });
  }

  handleITUserInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'phone') value = phoneMask(value);

    this.setState({ ITUser: { ...this.state.ITUser, [name]: value} });
  }

  handleInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({ [name]: value });
  }

  handleCompanyInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'cnpj') value = cnpjMask(value);
    if(name === 'cpf') value = cpfMask(value);
    if(name === 'zipCode'){
      value = cepMask(value);
      if(value.length === 9) this.getAddress(value, 'company');
    }

    this.setState({ company: {...this.state.company, [name]: value} });
  }

  handlePartnerInputChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    let partners = this.state.partners;

    if(name === 'cpf') value = cpfMask(value);
    if(name === 'phone') value = phoneMask(value);

    partners[index] = { ...partners[index], [name]: value };

    this.setState({ partners })
    
  }

  getAddress = (zipCode, to) => {

    let zip = zipCode.replace('-', '');
    zip = parseInt(zip);
    axios.get(`https://site.i-maxpr.com/API/checkZip.php?zip=${zip}`)
      .then(res => {
        let data = {
          address: res.data.address,
          neigh: res.data.neigh,
          state: res.data.state,
          city: res.data.town
        };

        this.setState({ [to]: { ...this.state[to], ...data }});
      });
  }
  
  render() {
    const { showCNPJ, showCPF } = this.state
    return (
      <>   
      <div className="container">
        <div className="summary">
          <Row>
            <Col sm={12}>
              <h3 className="title">MAILING I'MAX</h3>
              <h5 className="title">Resumo da proposta</h5>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className="boxQuestions">
                <p className="title">Valor do contrato</p>
                <p className="answer">{this.state.value}</p>
                <hr />
              </div>
              <div className="boxQuestions">
                <p className="title">Tempo de uso</p>
                <p className="answer">{this.state.installments}</p>
                <hr />
              </div>
              <div className="boxQuestions">
                <p className="title">Quantidade de usuários com acesso à plataforma</p>
                <p className="answer">{this.state.users}</p>
                <hr />
              </div>
              <div className="boxQuestions">
                <p className="title">Quantidade de disparos de e-mails</p>
                <p className="answer">{this.state.submissions}</p>
                <hr />
              </div>
            </Col>
            <Col sm={6}>
              <div className="boxQuestions">
                <p className="title">Mailing de Imprensa Brasil</p>
                <p className="answer">{this.state.mailing}</p>
                <hr />
              </div>
              <div className="boxQuestions">
                <p className="title">Públicos adicionais</p>
                <p className="answer">Mailing Governo e Terceiro Setor: <span>{this.state.government}</span></p>
                <p className="answer">Mailing de Influenciadores: <span>{this.state.influencer}</span></p>
                <p className="answer">Mailing de Imprensa LATAM: <span>{this.state.latam}</span></p>
                <hr />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="boxGray textCenter" id="boxGraySummary">
        <div className="container">
          <Row>
            <Col sm={12}>
              <h4 className="title">COMO VOCÊ PREFERE ASSINAR O SEU TERMO DE USO?</h4>
              <button className={`btnCNPJ${showCNPJ ? ' active' : ''}`} onClick={() => this.setState({ showCNPJ: true,  showCPF: false })}>COMO PESSOA JURÍDICA<br /><span>Utilizando o CNPJ da sua empresa</span></button>
              <button className={`btnCPF${showCPF ? ' active' : ''}`} onClick={() => this.setState({ showCPF: true,  showCNPJ: false })}>COMO PESSOA FÍSICA<br /><span>Utilizando o seu próprio CPF</span></button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container">
        <Row>
          <Col sm={12}>
            <h4 className="title titleSummary">Preencha a ficha administrativa para acessar o seu termo de uso e assiná-lo digitalmente</h4>
          </Col>
        </Row>
      </div>
      { showCNPJ && (
        <div className="boxCNPJ">
          <div className="container">
            <Row>
              <Col sm={6}>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OBRIGATÓRIO</p>
                  <p className="title">Dados da sua empresa</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CNPJ" name="cnpj" value={this.state.company.cnpj} onChange={this.handleCompanyInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Razão Social" name="companyName" value={this.state.company.companyName} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome fantasia" name="tradingName" value={this.state.company.tradingName} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col sm={6} className="col-ie">
                        <Form.Group>
                          <Form.Control type="text" placeholder="Inscrição estadual" disabled={this.state.checkIe ? true : false} name="ie" value={this.state.company.ie} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                        <Form.Check 
                          type="checkbox" 
                          id="checkIe"
                          label="Isento"
                          value={this.state.checkIe}
                          onChange={this.handleCheckIe}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Inscrição municipal" name="ccm" value={this.state.company.ccm} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CEP"  name="zipCode" value={this.state.company.zipCode} onChange={this.handleCompanyInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Endereço" name="address" value={this.state.company.address} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Número" name="number" value={this.state.company.number} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Complemento" name="complement" value={this.state.company.complement} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={9}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Cidade" name="city" value={this.state.company.city} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="UF" name="state" value={this.state.company.state} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Bairro" name="neigh" value={this.state.company.neigh} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Site da empresa" name="website" value={this.state.company.website} onChange={this.handleCompanyInputChange}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OBRIGATÓRIO</p>
                  <p className="title">Key Account</p>
                  <p style={{fontWeight: 400, fontSize: 15}}>Usuário(a) master da plataforma que será responsável pelas configurações da conta e pela inclusão de mais usuários(as)*</p>
                  <Row>
                    <Col className="checkKeyAccount">
                      <Form.Check 
                        type="checkbox" 
                        id="checkKeyAccount"
                        label="Usar dados do(a) sócio(a) ou responsável legal"
                        value={this.state.checkKeyAccount}
                        onChange={this.handleCheckKeyAccountChange} />
                    </Col>
                  </Row>
                  {!this.state.checkKeyAccount &&
                    <Form>
                      <Form.Row>
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Control type="text" placeholder="Nome completo"  name="name" value={this.state.keyAccount.name} onChange={this.handleKeyAccountInputChange} />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Control type="text" placeholder="E-mail corporativo" name="email" value={this.state.keyAccount.email} onChange={this.handleKeyAccountInputChange} />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.keyAccount.phone} onChange={this.handleKeyAccountInputChange} />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                    </Form>
                  }
                  <span>*.: a inclusão de mais usuários(as) é exclusiva para planos com mais de um acesso à plataforma</span>

                </div>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OPCIONAL</p>
                  <p className="title">Usuário(a) do Departamento Político</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome completo" name="name" value={this.state.politicalUser.name} onChange={this.handlePoliticalUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="E-mail corporativo" name="email" value={this.state.politicalUser.email} onChange={this.handlePoliticalUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.politicalUser.phone} onChange={this.handlePoliticalUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
              </Col>
              <Col sm={6}>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OBRIGATÓRIO</p>
                  <p className="title">Sócia(a) administrador(a) ou responsável legal pela assinatura</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome completo" name="name" value={this.state.partners[0]?.name} onChange={(e) => this.handlePartnerInputChange(e, 0)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="E-mail corporativo" name="email" value={this.state.partners[0]?.email} onChange={(e) => this.handlePartnerInputChange(e, 0)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.partners[0]?.phone} onChange={(e) => this.handlePartnerInputChange(e, 0)}  />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control type="text" placeholder="RG" name="rg" value={this.state.partners[0]?.rg} onChange={(e) => this.handlePartnerInputChange(e, 0)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CPF" name="cpf" value={this.state.partners[0]?.cpf} onChange={(e) => this.handlePartnerInputChange(e, 0)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OPCIONAL</p>
                  <p className="title">Segundo(a) sócio(a) administrador(a) ou responsável legal pela assinatura</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome completo" name="name" value={this.state.partners[1]?.name} onChange={(e) => this.handlePartnerInputChange(e, 1)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="E-mail corporativo" name="email" value={this.state.partners[1]?.email} onChange={(e) => this.handlePartnerInputChange(e, 1)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.partners[1]?.phone} onChange={(e) => this.handlePartnerInputChange(e, 1)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="RG" name="rg" value={this.state.partners[1]?.rg} onChange={(e) => this.handlePartnerInputChange(e, 1)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CPF" name="cpf" value={this.state.partners[1]?.cpf} onChange={(e) => this.handlePartnerInputChange(e, 1)} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OBRIGATÓRIO</p>
                  <p className="title">Responsável Financeiro</p>
                  <p style={{fontWeight: 400, fontSize: 15, marginBottom: 20}}>Adicione quantos e-mails quiser para receber cópias dos dados financeiros. Separa cada e-mail por vírgula e espaço.</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group> 
                          <Form.Control type="text" placeholder="E-mail" name="financialEmail" value={this.state.financialEmail} onChange={this.handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OPCIONAL</p>
                  <p className="title">Usuário(a) do Departamento de TI</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome completo" name="name" value={this.state.ITUser.name} onChange={this.handleITUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="E-mail corporativo" name="email" value={this.state.ITUser.email} onChange={this.handleITUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.ITUser.phone} onChange={this.handleITUserInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      { showCPF && (
        <div className="boxCPF">
          <div className="container">
            <Row>
              <Col sm={12}>
                <div className="boxFilters boxSummary">
                  <p className="fontSmall">OBRIGATÓRIO</p>
                  <Form>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Nome completo" name="name" value={this.state.person.name} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Control type="email" placeholder="E-mail" name="email" value={this.state.person.email} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Telefone celular" name="phone" value={this.state.person.phone} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CPF" name="cpf" value={this.state.person.cpf} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="CEP" name="zipCode" value={this.state.person.zipCode} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={8}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Endereço" name="address" value={this.state.person.address} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Número" name="number" value={this.state.person.number} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Complemento" name="complement" value={this.state.person.complement} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm={5}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Bairro" name="neigh" value={this.state.person.neigh} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={5}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Cidade" name="city" value={this.state.person.city} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="UF" name="state" value={this.state.person.state} onChange={this.handlePersonInputChange} />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className="container">
        <Row>
          <Col sm={12}>
            <button id="seeSummary" onClick={() => this.next()}>Visualizar contrato</button>
          </Col>
        </Row>
      </div>
      </>
    );
  }
}  