import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Range from '../../components/Range';

import './styles.css';

export default class Home extends React.Component {
    render() {
      return (
        <>
        <div className="boxGray">
          <div className="container">
            <Row>
              <Col sm={12}>
                  <h3 className="title">MAILINGS I'MAX</h3>
                  <p>Defina a faixa de preço para ver <b>o preço dos planos disponíveis</b></p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="boxWhite">
          <div className="container">
            <Row>
              <Col sm="12" md={{ size: 8, offset: 2 }}>                
                <h3>Qual é a faixa de preço que você planeja pagar por mês no plano do seu Mailing I'Max?</h3>
                <Range />            
              </Col>
              <Col md="2"></Col>
            </Row>
          </div>
        </div>
        <div className="boxGray textCenter">
          <div className="container">
            <Row>
              <Col sm={12}>
                <button>Quero contratar o disparo de um release apenas</button>
              </Col>
            </Row>
          </div>
        </div>
      </>
      );
    }
}  