import React from 'react';

import ContractWaiting from '../../components/ContractWaiting';

import './styles.css';

export default class ContratoWaiting extends React.Component {
    render() {
      return (
        <>
          <ContractWaiting /> 
        </>
      );
    }
}  