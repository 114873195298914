import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { RiCloseCircleLine } from 'react-icons/ri';

import './styles.scss';

export default class ContractWaiting extends React.Component {
  state = {
    signers: [
      '',
      ''
    ],
    manager: '',
    witnesses: [
      '',
      ''
    ],
    cc: '',
    
  }

  componentDidMount(){
    this.getInfos();
  }

  getInfos = () => {
    let { contract } = window.getURLparams();

    console.log(contract);

    this.setState({
      witnesses: contract.witnesses,
      manager: contract.manager,
      signers: contract.signers,
      cc: contract.cc
    });
  }

  render() {
    return (
      <>
      <div className="container">
        <div className="contract contractWaiting">
          <Row>
            <Col sm={12}>
              <h3 className="title">TERMO DE USO DO MAILING I'MAX</h3>
              <h5 className="title">Esperando assinatura digital para ativar o seu plano</h5>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
            <Form>
                <Form.Row>
                  <Col sm={12}>
                    <span>Contatos enviados para assinar:</span>
                    <Form.Group>
                      <Form.Control type="text" value={this.state.signers[0]} readOnly />
                    </Form.Group>
                    {this.state.signers[1] && 
                      <Form.Group>
                        <Form.Control type="text" value={this.state.signers[1]} readOnly />
                      </Form.Group>
                    }
                    <Form.Group>
                      <Form.Control type="text" value={this.state.manager} readOnly />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    <span>Contato enviado como testemunha:</span>
                    <Form.Group>
                      <Form.Control type="text" value={this.state.witnesses[0]} readOnly />
                    </Form.Group>
                    {this.state.witnesses[1] && 
                      <Form.Group>
                        <Form.Control type="text" value={this.state.witnesses[1]} readOnly />
                      </Form.Group>
                    }
                  </Col>
                </Form.Row>
                {this.state.cc &&
                  <Form.Row>
                    <Col sm={12}>
                      <span>Contato enviado cópia do termo de uso:</span>
                      <Form.Group>
                        <Form.Control type="text" value={this.state.cc} readOnly />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                }
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      </>
    );
  }
}  